import _path from "path";
import _filenameRegex from "filename-regex";
import _arrDiff from "arr-diff";
import _arrayUnique from "array-unique";
import _braces from "braces";
import _expandBrackets from "expand-brackets";
import _extglob from "extglob";
import _isExtglob from "is-extglob";
import _isGlob from "is-glob";
import _kindOf from "kind-of";
import _normalizePath from "normalize-path";
import _object from "object.omit";
import _parseGlob from "parse-glob";
import _regexCache from "regex-cache";
import _process from "process";
var exports = {};
var process = _process;
var win32 = process && process.platform === "win32";
var path = _path;
var fileRe = _filenameRegex;
var utils = exports;
/**
 * Module dependencies
 */

utils.diff = _arrDiff;
utils.unique = _arrayUnique;
utils.braces = _braces;
utils.brackets = _expandBrackets;
utils.extglob = _extglob;
utils.isExtglob = _isExtglob;
utils.isGlob = _isGlob;
utils.typeOf = _kindOf;
utils.normalize = _normalizePath;
utils.omit = _object;
utils.parseGlob = _parseGlob;
utils.cache = _regexCache;
/**
 * Get the filename of a filepath
 *
 * @param {String} `string`
 * @return {String}
 */

utils.filename = function filename(fp) {
  var seg = fp.match(fileRe());
  return seg && seg[0];
};
/**
 * Returns a function that returns true if the given
 * pattern is the same as a given `filepath`
 *
 * @param {String} `pattern`
 * @return {Function}
 */


utils.isPath = function isPath(pattern, opts) {
  opts = opts || {};
  return function (fp) {
    var unixified = utils.unixify(fp, opts);

    if (opts.nocase) {
      return pattern.toLowerCase() === unixified.toLowerCase();
    }

    return pattern === unixified;
  };
};
/**
 * Returns a function that returns true if the given
 * pattern contains a `filepath`
 *
 * @param {String} `pattern`
 * @return {Function}
 */


utils.hasPath = function hasPath(pattern, opts) {
  return function (fp) {
    return utils.unixify(pattern, opts).indexOf(fp) !== -1;
  };
};
/**
 * Returns a function that returns true if the given
 * pattern matches or contains a `filepath`
 *
 * @param {String} `pattern`
 * @return {Function}
 */


utils.matchPath = function matchPath(pattern, opts) {
  var fn = opts && opts.contains ? utils.hasPath(pattern, opts) : utils.isPath(pattern, opts);
  return fn;
};
/**
 * Returns a function that returns true if the given
 * regex matches the `filename` of a file path.
 *
 * @param {RegExp} `re`
 * @return {Boolean}
 */


utils.hasFilename = function hasFilename(re) {
  return function (fp) {
    var name = utils.filename(fp);
    return name && re.test(name);
  };
};
/**
 * Coerce `val` to an array
 *
 * @param  {*} val
 * @return {Array}
 */


utils.arrayify = function arrayify(val) {
  return !Array.isArray(val) ? [val] : val;
};
/**
 * Normalize all slashes in a file path or glob pattern to
 * forward slashes.
 */


utils.unixify = function unixify(fp, opts) {
  if (opts && opts.unixify === false) return fp;

  if (opts && opts.unixify === true || win32 || path.sep === "\\") {
    return utils.normalize(fp, false);
  }

  if (opts && opts.unescape === true) {
    return fp ? fp.toString().replace(/\\(\w)/g, "$1") : "";
  }

  return fp;
};
/**
 * Escape/unescape utils
 */


utils.escapePath = function escapePath(fp) {
  return fp.replace(/[\\.]/g, "\\$&");
};

utils.unescapeGlob = function unescapeGlob(fp) {
  return fp.replace(/[\\"']/g, "");
};

utils.escapeRe = function escapeRe(str) {
  return str.replace(/[-[\\$*+?.#^\s{}(|)\]]/g, "\\$&");
};
/**
 * Expose `utils`
 */


exports = utils;
export default exports;