var exports = {};
var chars = {},
    unesc,
    temp;

function reverse(object, prepender) {
  return Object.keys(object).reduce(function (reversed, key) {
    var newKey = prepender ? prepender + key : key; // Optionally prepend a string to key.

    reversed[object[key]] = newKey; // Swap key and value.

    return reversed; // Return the result.
  }, {});
}
/**
 * Regex for common characters
 */


chars.escapeRegex = {
  "?": /\?/g,
  "@": /\@/g,
  "!": /\!/g,
  "+": /\+/g,
  "*": /\*/g,
  "(": /\(/g,
  ")": /\)/g,
  "[": /\[/g,
  "]": /\]/g
};
/**
 * Escape characters
 */

chars.ESC = {
  "?": "__UNESC_QMRK__",
  "@": "__UNESC_AMPE__",
  "!": "__UNESC_EXCL__",
  "+": "__UNESC_PLUS__",
  "*": "__UNESC_STAR__",
  ",": "__UNESC_COMMA__",
  "(": "__UNESC_LTPAREN__",
  ")": "__UNESC_RTPAREN__",
  "[": "__UNESC_LTBRACK__",
  "]": "__UNESC_RTBRACK__"
};
/**
 * Unescape characters
 */

chars.UNESC = unesc || (unesc = reverse(chars.ESC, "\\"));
chars.ESC_TEMP = {
  "?": "__TEMP_QMRK__",
  "@": "__TEMP_AMPE__",
  "!": "__TEMP_EXCL__",
  "*": "__TEMP_STAR__",
  "+": "__TEMP_PLUS__",
  ",": "__TEMP_COMMA__",
  "(": "__TEMP_LTPAREN__",
  ")": "__TEMP_RTPAREN__",
  "[": "__TEMP_LTBRACK__",
  "]": "__TEMP_RTBRACK__"
};
chars.TEMP = temp || (temp = reverse(chars.ESC_TEMP));
exports = chars;
export default exports;